import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Center } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import TitledBox from "../../components/core/titled-box.component";

const nodeFormFields = [
    {
        id: 'name',
        tag: 'name',
        title: 'Name',
        value: '',
        validator: () => {},
    },
    {
        id: 'host',
        tag: 'host',
        title: 'Host',
        value: '',
        validator: () => {},
    },
]

const AddNode = ({ fields = nodeFormFields, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <>
            {fields.length > 0 ? (
                <Formik
                    initialValues={fields.reduce((values, f) => ({ ...values, [f.id]: f.value }), {})}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            onSubmit({ values })
                            actions.setSubmitting(false)
                        }, 1000)
                    }}
                >
                    {(props) => (
                    <Form>
                        {fields.map(({ id, title, value, validator }) => (
                            <Field key={`f-${id}`} name={id} validate={validator}>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors?.[id] && form.touched?.[id]} paddingBottom={2}>
                                        <Input {...field} id={id} placeholder={title} type="text" />
                                        <FormErrorMessage>{form.errors[id]}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        ))}
                        <Button
                            mt={4}
                            bgColor="#043c54"
                            color="white"
                            isLoading={props.isSubmitting}
                            type='submit'
                            width={"100%"}
                        >
                            {t('core.submit')}
                        </Button>
                    </Form>
                    )}
                </Formik>
            ) : (
                <Center>
                    <Spinner color='gray.500' size='lg' />
                </Center>
            )}
        </>
    );
}

export default AddNode;