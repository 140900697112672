import { Flex, Image, Stack } from "@chakra-ui/react"
import { useState } from "react"
import { useEffect } from "react"
import { apiUrlGenerator, apiUrls } from "../../constants/api.constant";

const offlineIcon = apiUrlGenerator('/img/wolke-grau-check@2x.png');
const onlineIcon = apiUrlGenerator('/img/wolke-gruen@2x.png');

const NodeListingPage = ({ }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState([])

    useEffect(() => {
        fetch(apiUrlGenerator(apiUrls.nodeListing.list))
        .then(data => data.json())
        .then(data => data.data)
        .then(newItems => setItems(newItems))
        .catch(error => console.log('Failed to load items'))
    }, [])

    return (
        <Stack style={{minWidth: '500px', width: '70%', maxWidth: '1200px' }}>
                {items.map(({ id, name, host, status }) => 
                    (
                        <Flex key={`host-${id}`} flexDir="row" style={{ height: '65px' }}>        
                            <Flex style={{ backgroundColor: 'white', padding: '15px', justifyContent: 'center', alignItems: 'center', marginRight: '2px' }}>
                                <div>
                                    <Image src={status ? onlineIcon : offlineIcon} width={'40px'} />
                                </div>
                            </Flex>
                            <Flex flex={1} style={{ backgroundColor: 'white', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px', fontWeight: '500' }}>
                                <span style={{ color: '#3d4650'}}>{name}</span>
                            </Flex>
                            <Flex style={{ backgroundColor: 'white', paddingLeft: '15px', paddingRight: '15px', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'}}>
                                {status ? 
                                    <span style={{ color: '#32cd32'}}>{'Online'}</span>
                                    :
                                    <span style={{ color: '#3d4650'}}>{'Offline'}</span>
                                }
                            </Flex>            
                        </Flex>
                    )
                )}
        </Stack>
    )
}

export default NodeListingPage