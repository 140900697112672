import React, { useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  theme,
} from '@chakra-ui/react';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import Logo from './components/core/logo.component';
import SideNavigation from './components/navigation/side-navigation.component';
import RequireAuth from './components/routing/require-auth.component';

import Login from './pages/login/login.page';
import Register from './pages/register/register.page';
import Home from './pages/home/home.page';
// import Analytics from './pages/analytics/analytics.page';
import Node from './pages/node/node.page';

import NAVIGATION_ITEMS from './constants/navigation-items.constant';
import LINK_NAMES from './constants/links.constant';
import './App.styles.scss';
import { useSelector } from 'react-redux';
import { authHelper } from './helpers/auth/auth.helper';
import NodeListingPage from './pages/node-listing/node-listing.page';


function App() {
  const isAuthenticated = useSelector(state => authHelper.isAuthenticated(state));
  // const currentUser = useSelector(state => authHelper.currentUser(state));
  // const currentToken = useSelector(state => authHelper.currentToken(state));
  const location = useLocation()
  
  return (
    <ChakraProvider theme={theme}>
      {!isAuthenticated ? (
        <Flex flex="1" minH="100vh" alignItems="center" justifyContent="center" className="app-container-full" bgGradient={location.pathname === LINK_NAMES.HOME ? 'linear(to-b, gray.100, gray.100)' : 'linear(to-b, gray.50, #043c54)'}>
          <Routes>
            <Route path={LINK_NAMES.HOME} element={<NodeListingPage />} />
            <Route path={LINK_NAMES.LOGIN} element={<Login />} />
            <Route path="*" element={<Navigate to={LINK_NAMES.LOGIN} replace />} />
          </Routes>
        </Flex>
      ) : (
        <Flex flexDir="row" minH="100vh" className="app-container">
          <Flex flexBasis="260" flexDir="column" alignItems="center" className="app-sidebar" bgGradient='linear(to-b, gray.100, #043c54)'>
            <Logo title="Andeer Server Status" className="app-logo" />
            <Box paddingTop="5" paddingBottom="5">
              <SideNavigation items={NAVIGATION_ITEMS} />
            </Box>
          </Flex>
          <Flex flex="1" flexDir="column" className="app-content" bgGradient='linear(to-r, gray.200, gray.50)'>
            <Routes>
              <Route path={LINK_NAMES.ADD_NODE} element={
                  <RequireAuth>
                    <Node />
                  </RequireAuth>
                } />
              <Route path="*" element={<Navigate to={LINK_NAMES.ADD_NODE} replace />} />
            </Routes>
          </Flex> 
        </Flex>
      )}
    </ChakraProvider>
  );
}

export default App;
