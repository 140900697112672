import { Box, Text } from "@chakra-ui/layout";
import SimpleBox from "./simple-box.component";


const TitledBox = ({
    title,
    titleBg = 'gray.100',
    titleColor = 'gray.600',
    contentBg = 'gray.50',
    borderRadius = 10,
    borderColor = 'gray.200',
    paddingTitleH = 4,
    paddingTitleV = 2,
    paddingContent = 4, 
    children
}) => {
    
    return (
        <SimpleBox
            p={0}
            borderRadius={borderRadius}
        >
            <Box
                bg={titleBg}
                borderBottomWidth={1}
                borderColor={borderColor}
                paddingLeft={paddingTitleH}
                paddingRight={paddingTitleH}
                paddingBottom={paddingTitleV}
                paddingTop={paddingTitleV}
                borderTopRadius={borderRadius}
            >
                <Text color={titleColor}>{title}</Text>
            </Box>
            <Box
                bg={contentBg}
                p={paddingContent}
                borderBottomRadius={borderRadius}
            >
                {children}
            </Box>
        </SimpleBox>
    );
};

export default TitledBox;