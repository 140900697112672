import { apiUrlGenerator, apiUrls } from "../constants/api.constant"
import api from "../utils/api.util"

export const fetchNodeItems = async () => {
    const records = (await api.get(apiUrlGenerator(apiUrls.node.list))).data
    return records.data
}

export const deleteNodeItem = async (id) => {
    const response = (await api.delete(apiUrlGenerator(apiUrls.node._delete(id)))).data
    return response
}

export const addNodeItem = async (name, host) => {
    const response = (await api.post(apiUrlGenerator(apiUrls.node.create), {
        name,
        host,
    })).data
    return response
}

export const editNodeItem = async (id, name, host) => {
    const response = (await api.put(apiUrlGenerator(apiUrls.node._update(id)), {
        name,
        host,
    })).data
    return response
}