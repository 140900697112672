import { Image, keyframes } from '@chakra-ui/react';
import logo from '../../assets/images/logo.png';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(0deg); }
`;

const Logo = ({ title = '', ...props}) => {
    const animation = `${spin} infinite 20s linear`;

    return (
        <Image
            animation={animation}
            src={logo}
            // align="center"
            // fit="contain"
            alt={title}
            {...props}
        />
    );
}

export default Logo;