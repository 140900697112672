import { Link } from "react-router-dom"
import { Box, Flex, Text } from "@chakra-ui/layout"
import { FormControl, FormErrorMessage, } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Button } from "@chakra-ui/button"
import { Field, Form, Formik } from "formik"
import Logo from '../../components/core/logo.component'

import { useLocation, useNavigate } from "react-router"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useToast } from "@chakra-ui/toast"

import { setCurrentToken, setCurrentUser } from "../../redux/user/user.redux"
import { attemptLogin } from "../../services/auth.service"

import LINK_NAMES from '../../constants/links.constant'
import { saveApiTokenAndSetAuthorizationHeader } from "../../utils/api.util"

const Login = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const toast = useToast()
  
    const from = location.state?.from?.pathname || "/";
  
    const loginUser = ({ email, password }) => 
        dispatch => 
            attemptLogin({ email, password })
            .then(({ user, token }) => {
                saveApiTokenAndSetAuthorizationHeader(token)
                dispatch(setCurrentUser(user))
            })
            .then(() => navigate(from, { replace: true }))
            .catch(err => {
                console.error(err)
                toast({
                    title: t('login.authenticationFailed'),
                    status: 'error',
                    isClosable: true
                })
            })

    const handleSubmit = ({ values }) => {
        const { email, password } = values

        // attempt login
        dispatch(loginUser({ email, password }))
    }

    const valudateEmailOrPhoneNumber = (value) => {
        const reMail = /^[a-zA-Z0-9\.]+[a-zA-Z0-9]@[a-zA-Z0-9\.]+[a-zA-Z0-9][\.]{1}[a-zA-Z]+$/
        const rePN = /^\+[1-9]+[0-9]{8,}$/
        let error
        if (!value) {
            error = t('login.emailOrPasswordIsRequired')
        }
        else if (!(rePN.test(value) || reMail.test(value))) {
            error = t('login.invalidPNorEmail')
        }
        return error
    }

    const validatePassword = (password) => {
        let error
        if (!password) {
            error = t('register.passwordIsRequired')
        } else if (password.length < 6) {
            error = t('register.minLength', { minLen: 6 })
        }
        return error
    }
  
    return (
        <Flex flexDir="column">
            <Flex maxW='sm'>
                <Logo style={{ padding: '20px' }} />
            </Flex>
            <Box maxW='sm' borderWidth='1px' p={5} bg="gray.50" borderRadius='lg' overflow='hidden'>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSubmit({ values })
                            actions.setSubmitting(false)
                        }, 1000)
                    }}
                >
                    {(props) => (
                    <Form>
                        <Field name='email' validate={valudateEmailOrPhoneNumber}>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.email && form.touched.email}>
                                    <Input {...field} id='email' placeholder={t('login.emailOrPhoneNumber')} />
                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Field name='password' validate={validatePassword}>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.password && form.touched.password} paddingTop={3}>
                                    <Input {...field} id='password' type='password' placeholder={t('login.password')} />
                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            mt={4}
                            bgColor='#043c54'
                            color='whiteAlpha.900'
                            isLoading={props.isSubmitting}
                            type='submit'
                            width={"100%"}
                        >
                            {t('login.signIn')}
                        </Button>
                    </Form>
                    )}
                </Formik>
            </Box>
        </Flex>
    )
}

export default Login;