import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        currentToken: null,
    },
    reducers: {
        setCurrentUser(state, action) {
            const user = action.payload
            state.currentUser = {...user}
        },
        setCurrentToken(state, action) {
            const token = action.payload
            state.currentToken = {...token}
        },
        resetCurrentUser(state, action) {
            state.currentUser = null
            state.currentToken = null
        },
    }
})

export const { setCurrentUser, resetCurrentUser, setCurrentToken } = userSlice.actions
export default userSlice.reducer