// English translation
export default {
    translation: {
        core: {
            "add": "Hinzufügen",
            "submit": "Einreichen",
            "failedToLoad": "Dienste konnten nicht geladen werden",
            "successUpdate": "Erfolgreich aktualisiert",
            "failUpdate": "Fehler beim Aktualisieren"
        },
        navigation: {
            "addNode": "Dienst hinzufügen",
            "listNodes": "Dienstleistungen",
            "signOut": "Ausloggen",
        },
        login: {
            "emailOrPhoneNumber": "Email",
            "password": "Passwort",
            "signIn": "Einloggen",
            "dontHaveAnAccount": "Noch nicht registriert?",
            "createAnAccount": "Ein Konto erstellen",
            "emailOrPasswordIsRequired": "E-Mail/Telefonnummer eingeben",
            "invalidPNorEmail": "Ungültige E-Mail/Telefonnummer",
            "authenticationFailed": "Authentifizierung fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail/Telefonnummer und/oder Ihr Passwort und versuchen Sie es erneut.",
        },
        register: {
            "email": "Email",
            "phoneNumber": "Phone number",
            "repeatPassword": "Passwort erneut eingeben",
            "signUp": "Anmeldung",
            "alreadyHaveAnAccount": "Sie haben bereits ein Konto?",
            "signInNow": "Einloggen",
            "emailIsRequired": "E-Mail ist erforderlich",
            "emailIsInvalid": "E-Mail ist ungültig",
            "phoneNumberIsRequired": "Telefonnummer ist erforderlich",
            "phoneNumberIsInvalid": "Telefonnummer ist ungültig",
            "passwordIsRequired": "Passwort wird benötigt",
            "minLength": "Die Mindestlänge beträgt 6 Zeichen",
            "retypePassword": "Passwort erneut eingeben",
            "passwordDontMatch": "Passwörter stimmen nicht überein",
            "registrationFailed": "Telefonnummer und/oder E-Mail sind/ist bereits registriert",
        },
        nodes: {
            "services": "Dienstleistungen",
            "name": "Name",
            "host": "Server",
            "options": "Optionen",
            "removeWarningMessage": "Sind Sie sich sicher, dass Sie das entfernen wollen '{{name}}'?",
            "removeSuccessMessage": "'{{name}} ({{host}})' wurde erfolgreich entfernt.",
            "removeFailureMessage": "'{{name}} ({{host}})' konnte nicht entfernt werden.",
            "addService": "Dienst hinzufügen",
            "editService": "Dienst bearbeiten"
        }
    }
}