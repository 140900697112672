import { Box } from "@chakra-ui/layout"

const SimpleBox = ({ children, ...props }) => {
    
    return (
        <Box
            p={4}
            borderWidth={1}
            borderRadius={5}
            bg="gray.50"
            borderColor="gray.300"
            {...props}
        >
            {children}
        </Box>
    );
}

export default SimpleBox;