
export const apiUrls = {
    base: 'https://serv-health.andeer.gr/backend/public/api',
    auth: {
        login: '/login',
        register: '/register',
    },
    node: {
        list: '/nodes',
        create: '/nodes',
        _update: (id) => `/nodes/${id}`,
        _delete: (id) => `/nodes/${id}`,
    },
    nodeListing: {
        list: '/nodes-stat',
    }
}

export const urlGenerator = (url) => {
    return `https://serv-health.andeer.gr/${url}`
}

export const apiUrlGenerator = (url) => {
    return `${apiUrls.base}${url}`
}
