import { resetCurrentUser } from "../redux/user/user.redux";
import LINK_NAMES from "./links.constant";

const NAVIGATION_ITEMS = [
    {
        id: 3,
        tKey: 'nodes.services',
        path: LINK_NAMES.ADD_NODE,
    },
    {
        id: 4,
        tKey: 'navigation.signOut',
        path: LINK_NAMES.NULL,
        callback: (dispatch) => dispatch(resetCurrentUser())
    },
    {
        id: 5,
        tKey: 'navigation.home',
        path: LINK_NAMES.NODE_LISTING,
    },

];

export default NAVIGATION_ITEMS;