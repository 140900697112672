import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TitledBox from "../../components/core/titled-box.component";

const ListNode = ({ items, onRemove, onEdit }) => {
    const { t } = useTranslation()
    
    return (
        <TitledBox title={t('navigation.listNodes')}>
            <TableContainer>
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>{t('nodes.name')}</Th>
                            <Th>{t('nodes.host')}</Th>
                            <Th>{t('nodes.options')}</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {items.map(({ id, name, host }, index) => (
                            <Tr key={id}>
                                <Td>{name}</Td>
                                <Td>{host}</Td>
                                <Td>
                                    <EditIcon
                                        w={6}
                                        h={6}
                                        color='blue.600'
                                        cursor='pointer'
                                        marginRight={3}
                                        onClick={() => onEdit(index)}
                                    />
                                    <DeleteIcon
                                        w={6}
                                        h={6}
                                        color='red.500'
                                        cursor='pointer'
                                        onClick={() => onRemove(index)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </TitledBox>
    );
}

export default ListNode;