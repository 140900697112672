import { VStack } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LINK_NAMES from "../../constants/links.constant";
import { useDispatch } from "react-redux";

const SideNavigation = ({ items }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    return (
        <VStack>
            {items.map(({ id, tKey, path, callback }) => 
                path !== LINK_NAMES.NULL ? (
                    <Link key={`nav-${id}`} to={path}>
                        <Button borderRadius='0' width='260px'>{t(tKey)}</Button>
                    </Link>
                ) : (
                    <Button key={`nav-${id}`} borderRadius='0' width='260px' onClick={() => callback(dispatch)}>{t(tKey)}</Button>                    
                )
            )}
        </VStack>
    );
}

export default SideNavigation;