// English translation
export default {
    translation: {
        core: {
            "add": "Add",
            "submit": "Submit",
            "failedToLoad": "Failed to load services",
            "successUpdate": "Successfully updated",
            "failUpdate": "Failed to update"
        },
        navigation: {
            "home": "Home",
            "analytics": "Analytics",
            "addNode": "Add service",
            "listNodes": "Services",
            "signOut": "Sign out",
        },
        login: {
            "emailOrPhoneNumber": "Email or phone number",
            "password": "Password",
            "signIn": "Sign in",
            "dontHaveAnAccount": "Didn't register yet?",
            "createAnAccount": "Create an account",
            "emailOrPasswordIsRequired": "Type email/phone number",
            "invalidPNorEmail": "Invalid email/phone number",
            "authenticationFailed": "Authentication failed. Please check your email/phone number and/or password and retry.",
        },
        register: {
            "email": "Email",
            "phoneNumber": "Phone number",
            "repeatPassword": "Retype password",
            "signUp": "Sign Up",
            "alreadyHaveAnAccount": "Already have an account?",
            "signInNow": "Sign in",
            "emailIsRequired": "Email is required",
            "emailIsInvalid": "Email is invalid",
            "phoneNumberIsRequired": "Phone number is required",
            "phoneNumberIsInvalid": "Phone number is invalid",
            "passwordIsRequired": "Password is required",
            "minLength": "Minimum length is 6 characters",
            "retypePassword": "Retype password",
            "passwordDontMatch": "Passwords do not match",
            "registrationFailed": "Phone number and/or email are/is already registred",
        },
        nodes: {
            "services": "Services",
            "name": "Name",
            "host": "Host",
            "options": "Options",
            "removeWarningMessage": "Are you sure you want to remove '{{name}}'?",
            "removeSuccessMessage": "'{{name}} ({{host}})' was successufully removed.",
            "removeFailureMessage": "'{{name}} ({{host}})' failed to remove.",
            "addService": "Add service",
            "editService": "Edit service"
        }
    }
}