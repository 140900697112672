import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { authHelper } from "../../helpers/auth/auth.helper";

import LINK_NAMES from "../../constants/links.constant";

const RequireAuth = ({ children }) => {
    const isAuthenticated = useSelector(state => authHelper.isAuthenticated(state));
    const location = useLocation();
  
    if (location.pathname === LINK_NAMES.NODE_LISTING) {
      return children;
    }
    
    if (!isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to={LINK_NAMES.LOGIN} state={{ from: location }} replace />;
    }
  
    return children;
}

export default RequireAuth;