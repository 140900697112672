import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { apiUrlGenerator, apiUrls } from '../constants/api.constant';
import { store } from '../redux/store.redux';
import { resetCurrentUser, setCurrentToken } from '../redux/user/user.redux';

const api = axios.create()

api.defaults.headers = {
    ...api.defaults.headers,
    common: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    post: {
        'Content-Type': 'application/json',
    }
}

export const saveApiTokenAndSetAuthorizationHeader = (authToken) => {
    const { expires_in, token_type, access_token } = authToken;
    api.defaults.headers = {
      ...axios.defaults.headers,
      'Authorization': `Bearer ${access_token}`,
    };
  
    const expiresAt = +new Date() + (expires_in - 60) * 1000;
  
    // dispatch token to redux store
    store.dispatch(
        setCurrentToken({
            tokenType: token_type,
            accessToken: access_token,
            expiresAt,
        })
    );
};

api.interceptors.request.use(request => {
    // console.log(JSON.stringify(request, null, 2))   
    return request
})

api.interceptors.response.use((response) => {
    return response
}, async function (error) {
    // console.log(`[Axios Error] `, error);
  
    const originalRequestConfig = error.config;
    if (error?.response?.status === 401) {
        // session expired
        store.dispatch(resetCurrentUser())
    }
    return Promise.reject(error);
});
  
// const mock = new MockAdapter(api)

// mock.onPost(apiUrlGenerator(apiUrls.auth.login)).reply(200, {
//     name: "Chriss",
//     email: 'chriss@mail.ro',
//     token: 'my-token'
// })

export default api