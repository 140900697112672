import { createSlice } from "@reduxjs/toolkit";

const nodeSlice = createSlice({
    name: 'node',
    initialState: {
        items: []
    },
    reducers: {
        setItems(state, action) {
            const items = action.payload
            state.items = [...items]
        }
    }
})

export const { setItems } = nodeSlice.actions
export default nodeSlice.reducer