import { Button, Stack, StackDivider, useDisclosure } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNodeItem, deleteNodeItem, editNodeItem, fetchNodeItems } from "../../services/node.service";

import AddNode from "./add-node.component";
import ListNode from "./list-node.component";
import NodeModal from "./node-modal.component";

const FORM_MODE = {
    ADD_MODE: 2,
    EDIT_MODE: 1,
}

const getNodeFormFields = (nameValue, hostValue) => [
    {
        id: 'name',
        tag: 'name',
        title: 'Name',
        value: nameValue,
        validator: () => {},
    },
    {
        id: 'host',
        tag: 'host',
        title: 'Host',
        value: hostValue,
        validator: () => {},
    },
]

const Node = () => {
    const { t } = useTranslation()
    const toast = useToast()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [items, setItems] = useState([])
    const [formMode, setFormMode] = useState(FORM_MODE.ADD_MODE)
    const [editFields, setEditFields] = useState({
        id: 0,
        fields: []
    })

    const _loadServices = useCallback(() => {
        fetchNodeItems()
        .then(items => {
            setItems(items)
        })
        .catch(err => { 
            toast({
                description: t('core.failedToLoad'),
                status: 'error',
                isClosable: true
            })
        })
    }, [])

    const _submitAddNode = useCallback((data) => {
        const { host, name } = data.values
        addNodeItem(name, host)
        .then(() => {
            toast({
                description: t('core.successUpdate'),
                status: 'success',
                isClosable: true
            })
            onClose()
            _loadServices()
        })
        .catch(err => {
            toast({
                description: t('core.failUpdate'),
                status: 'error',
                isClosable: true
            })
        })
    }, [onClose, _loadServices])

    const _submitEditNode = useCallback((data) => {
        const { host, name } = data.values
        editNodeItem(editFields.id, name, host)
        .then(() => {
            toast({
                description: t('core.successUpdate'),
                status: 'success',
                isClosable: true
            })
            onClose()
            _loadServices()
        })
        .catch(err => {
            toast({
                description: t('core.failUpdate'),
                status: 'error',
                isClosable: true
            })
        })
    }, [editFields, _loadServices])

    const _editItem = useCallback((index) => {
        const item = items?.[index]
        if (item) {
            setEditFields({
                id: item.id,
                fields: getNodeFormFields(item.name, item.host)
            })
            setFormMode(FORM_MODE.EDIT_MODE)
        }
        onOpen()
    }, [items, setEditFields, setFormMode, onOpen])

    const _addItem = () => {
        setFormMode(FORM_MODE.ADD_MODE)
        setEditFields({
            id: 0,
            fields: []
        })
        onOpen()
    }

    const _removeItem = (index) => {
        const item = items?.[index];
        if(item && window.confirm(t('nodes.removeWarningMessage', { name: item?.name }))) {
            deleteNodeItem(item.id)
            .then(() => {
                setItems(items => items.filter(({ id }) => id !== item?.id))
                toast({
                    description: t('nodes.removeSuccessMessage', { name: item?.name, host: item?.host }),
                    status: 'success',
                    isClosable: true
                })
            })
            .catch(err => toast({
                description: t('nodes.removeFailureMessage', { name: item?.name, host: item?.host }),
                status: 'error',
                isClosable: true
            }))
        }
    }

    /* Effects */
    useEffect(() => {
        _loadServices()
    }, [])

    return (
        <Stack>
            <ListNode
                items={items}
                onEdit={_editItem}
                onRemove={_removeItem}
            />
            <Button bgColor='#043c54' color='white' mr={3} onClick={_addItem}>{t('nodes.addService')}</Button>
            {(formMode === FORM_MODE.ADD_MODE) ?
                <NodeModal
                    title={t('nodes.addService')}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                >
                    <AddNode
                        onSubmit={_submitAddNode}
                    />                    
                </NodeModal>
                :
                <NodeModal
                    title={t('nodes.editService')}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                >
                    <AddNode
                        fields={editFields.fields}
                        onSubmit={_submitEditNode}
                    />
                </NodeModal>
            }
        </Stack>
    );
}

export default Node;