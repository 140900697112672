import api from "../utils/api.util";
import { apiUrlGenerator, apiUrls } from "../constants/api.constant";

export const attemptLogin = async ({ email, password }) => {
    const response = (await api.post(apiUrlGenerator(apiUrls.auth.login), {
        email,
        password
    })).data;

    return { user: response.user, token: response.auth_token  };
}

export const attemptRegister = async ({ email, phoneNumber, password }) => {
    const response = (await api.post(apiUrlGenerator(apiUrls.auth.register), {
        email,
        phoneNumber,
        password,
    })).data;

    return { user: response.user, token: response.auth_token  };
}